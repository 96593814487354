import { QUIZ_TYPES } from '../resources/quiz-types';

export interface Question {
  id?: number;
  uuid?: string;
  text: string;
  answers?: {text: string, isSelected?: boolean, followUpQuestion?: Question}[];
  selectedAnswer?: number;
  isInhouseQuestion?: boolean;
  image?: string;
  video?: string;
  questionType?: TypeOfSkillsQuestion;
  maxNumberOfAnswersCandidateCanChoose?: number;
  freeAnswer?: string;
}

export interface Answer {
  text: string;
  correct?: boolean;
}

export interface InhouseQuestion {
  passAllInhouseCandidates: boolean;
  question?: Question;
}

export interface Quiz {
  type?: QUIZ_TYPES;
  questions?: Question[];
  inhouseQuestion?: Question;
  inhouseQuestionIncluded?: boolean;
  activeQuestion?: Question;
  activeQuestionIndex?: number;
  rejectionMessage?: string;
  rejectionMessageMain?: string;
  passAllCandidates?: boolean;
  passAllInhouseCandidates?: boolean;
  video?: string;
  q1?: string;
  q2?: string;
  q3?: string;
  q1answer?: string;
  q2answer?: string;
  q3answer?: string;
}

export interface VideoQuiz {
  video?: string;
  q1: string;
  q2?: string;
  q3?: string;
  q1answer?: string;
  q2answer?: string;
  q3answer?: string;
  videoMainMessage?: string;
  videoMessage?: string;
}

export interface QuizAnswer {
  answer?: number;
  freeAnswer?: string;
  questionId?: number;
  isInhouseQuestion?: boolean;
  hasFollowUpQuestion?: boolean;
}

export interface VideoAnswer {
    question: string,
    answer: string,
    replace: boolean;
    poster: string;
    isExternalLink: boolean;
}

export enum TypeOfSkillsQuestion {
  singleAnswerQuestion = 'singleAnswerQuestion',
  multipleAnswersQuestion = 'multipleAnswersQuestion',
  questionWithFollowUpQuestions = 'questionWithFollowUpQuestions',
  freeAnswerQuestion = 'freeAnswerQuestion',
}
